import { useState, useEffect, useMemo } from "react"
import tw, { styled } from "twin.macro"

import { FilterableTilesProps } from "components/dynamic"
import Section from "components/Section"
import TileGrid, { TileTypes } from "components/TileGrid"
import { H3, H6 } from "styles/Text"
import SRText from "components/SRText"

const FilterButton = styled(H6).attrs(() => ({
  role: "radio",
}))<{ "aria-checked": boolean }>`
  ${tw`py-3 px-1 mx-2`}
  ${(props) =>
    props["aria-checked"]
      ? tw`text-primary border-0 border-solid border-b-2 pb-2.5 border-primary`
      : null}
`

export const FilterableTiles = ({
  Title,
  id: componentId,
  generic_pages = [],
}: FilterableTilesProps) => {
  const [tiles, setTiles] = useState(generic_pages)
  const [filterId, setFilterId] = useState(null)
  const filters = useMemo(
    () =>
      generic_pages
        .map(({ page_type }) => page_type)
        .filter(
          (type, idx, self) =>
            type?.id && idx === self.map((t) => t?.id).indexOf(type?.id)
        ),
    [generic_pages]
  )

  useEffect(() => {
    if (filterId === null) {
      setTiles(generic_pages)
      return
    }
    setTiles(generic_pages.filter((page) => page?.page_type?.id === filterId))
  }, [filterId, generic_pages, setTiles])

  return (
    <Section gridSize={{ lg: 1 }}>
      <H3 as="h2" tw="text-center">
        {Title}
      </H3>
      <div
        tw="flex text-center justify-center mt-7.5 -mx-3 text-dark-grey"
        role="radiogroup"
        aria-controls={`filterable-tiles-${componentId}`}
        aria-labelledby={`filterable-tiles-${componentId}-filter-title`}
      >
        <SRText id={`filterable-tiles-${componentId}-filter-title`}>
          Filter by
        </SRText>
        <FilterButton
          as="button"
          aria-checked={filterId === null}
          onClick={() => setFilterId(null)}
        >
          All
        </FilterButton>
        {filters.map(({ id, Name }) => (
          <FilterButton
            as="button"
            key={id}
            aria-checked={filterId === id}
            onClick={() => setFilterId(id)}
          >
            {Name}
          </FilterButton>
        ))}
      </div>
      <TileGrid
        tiles={tiles}
        type={TileTypes.GENERIC}
        tw="mt-10"
        id={`filterable-tiles-${componentId}`}
      />
    </Section>
  )
}

export default FilterableTiles
